<template>
<div class="row">
  <div class="col-xl-12 mb-5">
    <h5 class="cui__utils__heading mb-5">
      <strong>Total Statistik</strong>
    </h5>
    <div class="dashboard-content">
      <div class="row">
        <div v-for="data in dataTopCard" :key="data.key" class="col-md-12 col-lg-3">
          <div class="card">
            <div class="card-body position-relative overflow-hidden">
              <div class="font-size-36 font-weight-bold text-dark mb-n2">{{data.amount}}</div>
              <div class="text-uppercase">{{data.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="col-xl-6">
      <h5>
        <strong>Total Pertumbuhan Akun Per Hari</strong>
      </h5>
      <div class="position-relative overflow-hidden">
        <BarChart
          :chart-data="dailyData"
          :options="options"
          css-classes="chart-container"
        />
      </div>
    </div>
    <div class="col-xl-6 mb-xl-5 pb-xl-5">
      <h5>
        <strong>Total Pertumbuhan Akun Per Minggu</strong>
      </h5>
      <div class="position-relative overflow-hidden">
        <BarChart
          :chart-data="weeklyData"
          :options="options"
          css-classes="chart-container"
        />
      </div>
    </div>
    <div class="col-xl-6">
      <h5>
        <strong>Total Pertumbuhan Akun Per Bulan</strong>
      </h5>
      <div class="position-relative overflow-hidden">
        <BarChart
          :chart-data="monthlyData"
          :options="options"
          css-classes="chart-container"
        />
      </div>
    </div>
    <div class="col-xl-6 ">
      <h5>
        <strong>Persentase User Per Jenis Kelamin</strong>
      </h5>
      <div class="position-relative overflow-hidden">
        <DoughnutChart
          :chart-data="doughnutData"
          :options="doughnutOptions"
          css-classes="chart-container"
        />
      </div>
    </div>
</div>
</template>

<script setup>
import pattern from "patternomaly"
import { ref, computed } from "vue"
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
import { BarChart, DoughnutChart } from "vue-chart-3"
import {
  Chart,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  DoughnutController,
  ArcElement,
} from "chart.js"
Chart.register(BarController, CategoryScale, LinearScale, BarElement, DoughnutController, ArcElement)
const dataValues = ref([
  [1, 3, 5, 7, 2, 4, 6],
])
const weeklyDataValues = ref([
  [10, 30, 50, 20, 25, 40, 60, 30],
])

const monthlyDataValues = ref([
  [100, 300, 500, 200, 250, 400, 600, 300, 100, 300, 500, 200],
])
const dataTopCard = ref([
  {
    key: 'totalPenjualan',
    title: 'Total Transaksi',
    amount: 100000,
  },
  {
    key: 'visitorCount',
    title: 'Total Top Up',
    amount: 5023,
  },
  {
    key: 'averageRating',
    title: 'Total Reports',
    amount: 587,
  },
  {
    key: 'averageRating',
    title: 'Total Post',
    amount: 100,
  },
])
const doughnutDataValues = ref([10, 90])
const dailyData = computed(() => ({
  labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  datasets: [
    {
      label: "Total per hari",
      data: dataValues.value[0],
      backgroundColor: [
        '#4B7CF3',
      '#FAAD15',
      '#41B883',
      '#FFFF00',
      '#5EB4EF',
      '#6FCDCD',
      'rgba(201, 203, 207, 0.2)',
      ],
      borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)',
    ],
    height: '110px',
        showPoint: true,
    },
  ],
}))
const weeklyData = computed(() => ({
  labels: ["Jan 7", "Jan 14", "Jan 21", "Jan 28", "Feb 4", "Feb 11", "Feb 18", "Feb 25"],
  datasets: [
    {
      label: "Total per minggu",
      data: weeklyDataValues.value[0],
      backgroundColor: [
        '#4B7CF3',
      ],
      height: '110px',
      showPoint: true,
    },
  ],
}))
const monthlyData = computed(() => ({
  labels: ["Jan 21", "Feb 21", "Mar 21", "Apr 21", "May 21", "Jun 21", "Jul 21", "Aug 21", "Sep 21", "Oct 21", "Nov 21", "Dec 21"],
  datasets: [
    {
      label: "Total per bulan",
      data: monthlyDataValues.value[0],
      backgroundColor: [
        '#41B883',
      ],
      height: '110px',
      showPoint: true,
    },
  ],
}))
const options = ref({
  plugins: {
  },
})

const doughnutData = computed(() => ({
  labels: ["Female", "Male"],
  datasets: [
    {
      data: doughnutDataValues.value,
      backgroundColor: ["#6FCDCD", "#5EB4EF"],
      hoverOffset: 4,
    },
  ],
}))
const doughnutOptions = ref({
  plugins: {
    title: {
      text: "Gender Percentage (%)",
    },
  },
  // plugins: [ChartistTooltip({ anchorToPoint: false, appendToBody: true, seriesName: false })],
})
</script>